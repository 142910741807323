<template>
  <div class="company-profile">
    <v-row no-gutters dense class="d-flex" style="height: inherit">
      <v-col cols="12" class="col-lg-6 pt-8 my-pr">
        <app-password></app-password>
      </v-col>
      <v-col cols="12" class="col-lg-6 pt-8 my-pl">
        <app-preference></app-preference>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const Password = () => import("./Password");
const Preference = () => import("./Preference");
export default {
  components: {
    AppPassword: Password,
    AppPreference: Preference,
  },
};
</script>

<style lang="scss" scoped>
.company-profile {
  height: 100% !important;
}
</style>